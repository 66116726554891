import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';

import { SpinnerComponent } from '../shared/spinner/spinner.component';

@NgModule({
  imports: [
    CommonModule,
    NgxSpinnerModule,
  ],
  exports: [
    SpinnerComponent,
  ],
  declarations: [
    SpinnerComponent,
  ],
})
export class CommonComponentsModule { }
