import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounce } from 'rxjs/operators';
import { interval } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { FeedbackService } from '../../services/feedback.service';
import { AuthService } from '../../services/auth.service';
import { enterLeaveAnimation, listAnimation } from '../../animations/animations';
import { SpinnerService } from '../../services/spinner.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-outbox',
  templateUrl: './outbox.component.html',
  styleUrls: ['./outbox.component.scss'],
  animations: [enterLeaveAnimation, listAnimation],
})
export class OutboxComponent implements OnInit {
  feedbacksAll: Array<any> = [];
  feedbacks: Array<any>;
  isFeedbacksEmpty: boolean;
  hasFeedbackToLoad = false;
  sortDirection = false;
  searchControl;
  searchSub;
  initialSpinner = 'initialSpinner';
  loadMoreSpinner = 'loadMoreSpinner';

  constructor(
    public feedbackService: FeedbackService,
    public spinnerService: SpinnerService,
    public authService: AuthService,
    private notificationService: NotificationService,
    private translate: TranslateService,
  ) {
    this.searchControl = new FormControl();
    this.searchSub = this.searchControl.valueChanges
      .pipe(debounce(() => interval(500)))
      .subscribe(this.filterFeedbacks.bind(this));
  }

  async ngOnInit() {
    this.loadFeedbacks();
  }

  async loadFeedbacks() {
    this.spinnerService.show(this.initialSpinner);

    this.feedbackService.getSentFeedback().subscribe((loadedFeedbacks: any) => {
      this.feedbacksAll = loadedFeedbacks;
      this.spinnerService.hide(this.initialSpinner);
      this.isFeedbacksEmpty = this.feedbacksAll.length === 0;
      this.hasFeedbackToLoad = this.feedbacksAll.length === 10;
        this.filterFeedbacks();
    });
  }

  filterFeedbacks() {
    const search = this.searchControl.value;

    this.feedbacks = !search
      ? this.feedbacksAll
      : this.feedbacksAll.filter((f) => (
          this.feedbackContentIncludesText(f, search) ||
          this.feedbackAuthorIncludesText(f, search)
        ));
    this.feedbacks.sort((fa, fb) => this.sortDirection
        ? fa.createdAt.seconds - fb.createdAt.seconds
        : fb.createdAt.seconds - fa.createdAt.seconds);
  }

  feedbackContentIncludesText(feedback, search) {
    const searchText = (search || ' ').toLowerCase();
    const feedbackContent = (feedback.content || ' ').toLowerCase();

    return feedbackContent.includes(searchText);
  }

  feedbackAuthorIncludesText(feedback, search) {
    const searchText = (search || ' ').toLowerCase();
    const feedbackAuthor = ((feedback.from && feedback.from.displayName) || ' ').toLowerCase();

    return feedbackAuthor.includes(searchText);
  }

  sort(ascending) {
    this.sortDirection = !!ascending;
    this.filterFeedbacks();
  }

  async loadOlder() {
    this.hasFeedbackToLoad = false;
    this.spinnerService.show(this.loadMoreSpinner);
    const lastFeedbackIndex = this.feedbacksAll.length - 1;

    this.feedbackService.getSentFeedback(this.feedbacksAll[lastFeedbackIndex]).subscribe((loadedFeedbacks: any) => {
      this.hasFeedbackToLoad = loadedFeedbacks.length >= 10;
      this.feedbacksAll = [...this.feedbacksAll, ...loadedFeedbacks];
      this.filterFeedbacks();
      this.spinnerService.hide(this.loadMoreSpinner);
    });
  }

  async deleteFeedback(id: string) {
    try {
      const confirmed = await this.notificationService.showConfirmationDialog({
        header: this.translate.instant('PAGES.OUTBOX.DELETE_FEEDBACK_HEADER'),
        content: this.translate.instant('PAGES.OUTBOX.DELETE_FEEDBACK_COPY'),
      });
      if (confirmed) {
        await this.feedbackService.deleteFeedback(id);
        this.loadFeedbacks();
        this.notificationService.showSuccessNotification(this.translate.instant('PAGES.OUTBOX.DELETE_FEEDBACK_SUCCESS'));
      }
    } catch (_) {
      this.notificationService.showGenericErrorNotification();
    }

  }
}
