import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  _enabledtheme = new Subject<string>();
  enabledTheme = this._enabledtheme.asObservable();

  enableTheme(newTheme): void {
    this._enabledtheme.next(newTheme);
  }
}
