import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { map, mergeMap, toArray } from 'rxjs/operators';

import { listAnimation, enterLeaveAnimation } from '../../animations/animations';
import { AdminService } from '../../services/admin.service';
import { SpinnerService } from './../../services/spinner.service';
import { StatsService } from '../../services/stats.service';
import { UsersService } from '../../services/users.service';
import { getNextDate, convertTimestampToDateString } from '../../utils/date';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss'],
  animations: [listAnimation, enterLeaveAnimation],
})
export class StatsComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;

  feedbucketSettings: any;
  releaseDate: Date;
  statsSpinner = 'statsSpinner';
  generalStatistics: any = {};

  // Weekly statistics chart parameters
  weeklyStatisticsData: any;
  public lineChartData: ChartDataSets[] = [
    { data: [], label: 'all kudo' },
    { data: [], label: 'anonymous kudo' },
  ];
  public lineChartLabels: Label[] = [];
  public lineChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            precision: 0,
          },
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    {
      borderColor: '#4a154b',
      backgroundColor: 'rgba(74, 21, 75, 0.4)',
    },
    {
      borderColor: '#e01e5a',
      backgroundColor: 'rgba(224, 30, 90, 0.4)',
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];

  // Most active users table parameters
  displayedColumns: string[] = ['position', 'email', 'sent', 'recv', 'symbol'];
  topStatsSub: Subscription;
  dataSourceMat: MatTableDataSource<any>;

  constructor(
    private adminService: AdminService,
    public spinnerService: SpinnerService,
    private statsService: StatsService,
    private translate: TranslateService,
    private usersService: UsersService,
  ) {
    this.translate.get('PAGES.STATS.CHART_ALL_KUDO').subscribe((translatedText) => {
      this.lineChartData[0].label = translatedText;
    });
    this.translate.get('PAGES.STATS.CHART_ANONYMOUS_KUDO').subscribe((translatedText) => {
      this.lineChartData[1].label = translatedText;
    });
  }

  ngOnInit() {
    this.spinnerService.show(this.statsSpinner);
    this.adminService.settings$.subscribe((item) => {
      this.feedbucketSettings = item.payload.data();
      this.releaseDate = getNextDate(this.feedbucketSettings.day, this.feedbucketSettings.hour);
      this.spinnerService.hide(this.statsSpinner);
    });
    this.statsService.getGeneralStats().subscribe((generalStats) => {
      this.generalStatistics = generalStats;
    });
    this.handleWeeklyStatistics();

    this.topStatsSub = this.statsService
      .getTopUserStats()
      .pipe(
        mergeMap((stats: any) =>
          this.usersService.getUserProfileData(stats.uid).pipe(
            map((userData: any) => ({
              ...userData,
              ...stats.all,
            })),
          ),
        ),
        toArray(),
      )
      .subscribe((topUsers) => {
        this.dataSourceMat = new MatTableDataSource(topUsers);
        this.dataSourceMat.sort = this.sort;
      });
  }

  ngOnDestroy(): void {
    this.topStatsSub.unsubscribe();
  }

  async handleWeeklyStatistics() {
    const stats = await this.statsService.getLastQuarterWeeklyStats();
    stats.forEach((weeklyStats) => {
      this.lineChartLabels.push(convertTimestampToDateString(weeklyStats.at));
      this.lineChartData[0]['data'].push(weeklyStats.allFeedback);
      this.lineChartData[1]['data'].push(weeklyStats.anonymousFeedbackSent);
    });
  }

}
