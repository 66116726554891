import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { interval } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { FeedbackService } from '../../services/feedback.service';
import { SpinnerService } from './../../services/spinner.service';
import { AuthService } from '../../services/auth.service';
import { enterLeaveAnimation, listAnimation } from '../../animations/animations';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss'],
  animations: [listAnimation, enterLeaveAnimation],
})
export class InboxComponent implements OnInit {
  feedbacksAll = [];
  feedbacks: Array<any>;
  isFeedbacksEmpty: boolean;
  hasFeedbackToLoad = false;
  sortDirection = false;
  searchControl;
  searchSub;
  initialSpinner = 'initialSpinner';
  loadMoreSpinner = 'loadMoreSpinner';
  createdAtTooltip: string;

  constructor(
    public feedbackService: FeedbackService,
    public spinnerService: SpinnerService,
    public authService: AuthService,
    private translate: TranslateService,
  ) {
    this.searchControl = new FormControl();
    this.searchSub = this.searchControl.valueChanges
      .pipe(debounce(() => interval(500)))
      .subscribe(this.filterFeedbacks.bind(this));
  }

  async ngOnInit() {
    this.spinnerService.show(this.initialSpinner);
    this.feedbackService.getReceivedFeedback().subscribe((loadedFeedbacks: any) => {
      this.feedbacksAll = loadedFeedbacks;
      this.spinnerService.hide(this.initialSpinner);
      this.filterFeedbacks();
      this.isFeedbacksEmpty = this.feedbacksAll.length === 0;
      this.hasFeedbackToLoad = this.feedbacksAll.length === 10;
      this.createdAtTooltip = this.translate.instant('PAGES.INBOX.TOOLTIP_DATE_CREATED');
    });
  }

  filterFeedbacks() {
    const search = this.searchControl.value;

    this.feedbacks = !search
      ? this.feedbacksAll
      : this.feedbacksAll.filter((feedback) =>
      this.feedbackContentIncludesText(feedback, search) || this.feedbackAuthorIncludesText(feedback, search));
    this.feedbacks.sort((fa, fb) => this.sortDirection
        ? fa.createdAt.seconds - fb.createdAt.seconds
        : fb.createdAt.seconds - fa.createdAt.seconds);
  }

  feedbackContentIncludesText(feedback, search) {
    const searchText = (search || ' ').toLowerCase();
    const feedbackContent = (feedback.content || ' ').toLowerCase();

    return feedbackContent.includes(searchText);
  }

  feedbackAuthorIncludesText(feedback, search) {
    const searchText = (search || ' ').toLowerCase();
    const feedbackAuthor = ((feedback.from && feedback.from.displayName) || ' ').toLowerCase();

    return feedbackAuthor.includes(searchText);
  }

  sort(ascending) {
    this.sortDirection = !!ascending;
    this.filterFeedbacks();
  }

  loadOlder() {
    this.spinnerService.show(this.loadMoreSpinner);
    this.hasFeedbackToLoad = false;
    const lastFeedbackIndex = this.feedbacksAll.length - 1;
    this.feedbackService.getReceivedFeedback(this.feedbacksAll[lastFeedbackIndex]).subscribe((loadedFeedbacks: any) => {
      this.hasFeedbackToLoad = loadedFeedbacks.length >= 10;
      this.feedbacksAll = [...this.feedbacksAll, ...loadedFeedbacks];
      this.filterFeedbacks();
      this.spinnerService.hide(this.loadMoreSpinner);
    });
  }
}
