import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(
    public firestore: AngularFirestore,
    public usersService: UsersService,
    private authService: AuthService,
  ) {}

  createFeedbackPayload(formData) {
    return {
      content: formData.content,
      to: formData.to,
      time: formData.time,
      delivered: false,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      from: this.authService.getCurrentUserUID(),
      anonymous: formData.anonymous,
    };
  }

  createFeedback(formData) {
    return this.firestore
      .collection(`feedback/${this.authService.getCurrentUserUID()}/sent`)
      .add(this.createFeedbackPayload(formData));
  }

  updateFeedback(formData, id: string) {
    return this.firestore
      .collection(`feedback/${this.authService.getCurrentUserUID()}/sent`)
      .doc(id)
      .set(this.createFeedbackPayload(formData));
  }

  deleteFeedback(id: string) {
    return this.firestore.collection(`feedback/${this.authService.getCurrentUserUID()}/sent`).doc(id).delete();
  }

  getSentFeedback(startAfter?: any) {
    const res$ = new Subject();
    this.usersService.getAllUsers().subscribe((users) => {
      this.firestore
        .collection(`feedback/${this.authService.getCurrentUserUID()}/sent`, this.sentFeedbackQuery(startAfter))
        .get()
        .pipe(
          map((queryResults) =>
            queryResults.docs.map((doc) => {
              const data = doc.data() as any;
              const id = doc.id;
              return { id, ...data };
            }),
          ),
          map((feedbacks) => feedbacks.map(this.replaceUidWithUser(users))),
        )
        .forEach((next) => {
          res$.next(next);
        });
    });

    return res$;
  }

  getReceivedFeedback(startAfter?: any) {
    const res$ = new Subject();
    this.usersService.getAllUsers().subscribe((users) => {
      this.firestore
        .collection(`feedback/${this.authService.getCurrentUserUID()}/received`, this.receivedFeedbackQuery(startAfter))
        .get()
        .pipe(
          map((queryResult) => queryResult.docs.map((doc) => doc.data())),
          map((feedbacks) => feedbacks.map(this.replaceUidWithUser(users))),
        )
        .forEach((feedbacks) => {
          res$.next(feedbacks);
        });
    });

    // TODO merge with anonymous feedbacks
    return res$;
  }

  getSingleSentFeedback(feedbackId: string): Observable<any> {
    const feedbackDocumentRef = this.firestore
      .collection(`feedback/${this.authService.getCurrentUserUID()}/sent`)
      .doc(feedbackId);
    const feedback$ = feedbackDocumentRef.snapshotChanges();
    return feedback$;
  }

  private replaceUidWithUser(users) {
    return (feedback) => ({
      ...feedback,
      from: users.find((user) => user.uid === feedback.from),
      to: feedback.to.map((recipient) => users.find((user) => user.uid === recipient)),
    });
  }

  private sentFeedbackQuery(startAfter?: any) {
    if (startAfter) {
      return (queryFn) => queryFn.orderBy('createdAt', 'desc').startAfter(startAfter.createdAt).limit(10);
    } else {
      return (queryFn) => queryFn.orderBy('createdAt', 'desc').limit(10);
    }
  }

  private receivedFeedbackQuery(startAfter?: any) {
    if (startAfter) {
      return (queryFn) => queryFn.orderBy('createdAt', 'desc').startAfter(startAfter.createdAt).limit(10);
    } else {
      return (queryFn) => queryFn.orderBy('createdAt', 'desc').limit(10);
    }
  }
}
