import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-success-dialog',
  templateUrl: './create-success-dialog.component.html',
})
export class CreateSuccessDialogComponent {
  time;

  constructor(
    public dialogRef: MatDialogRef<CreateSuccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.time = {value: data.time};
  }
}
