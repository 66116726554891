import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { User } from '../models/user.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  userProfileDocumentRef: AngularFirestoreDocument<any>;
  private users$: Observable<any[]>;

  constructor(public db: AngularFirestore, private authService: AuthService) {
    this.userProfileDocumentRef = this.db.collection('users').doc(this.authService.getCurrentUserUID());
    this.users$ = this.db
      .collection<User>('users')
      .get()
      .pipe(map((actions) => actions.docs.map((action) => action.data())));
  }

  updateUserProfile(value) {
    return this.userProfileDocumentRef.update(value);
  }

  getAllUsers() {
    return this.users$;
  }

  getUserProfileData(uid) {
    const res$ = new Subject();
    this.users$.subscribe(
      (users) => {
        res$.next(users.find((user) => user.uid === uid));
      },
      (e) => res$.error(e),
      () => res$.complete(),
    );
    return res$;
  }
}
