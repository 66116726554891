import { TranslateService } from '@ngx-translate/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipDefaultOptions,
} from '@angular/material/tooltip';

import { AuthService } from '../../services/auth.service';
import { NotificationService } from '../../services/notification.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { Subscription } from 'rxjs';
import { getLanguageOptions } from '../../utils/language';
import { getThemeOptions } from '../../utils/theme.util';
import { routeAnimations } from '../../animations/animations';
import { ThemeService } from '../../services/theme.service';
import { ThemeEnum } from 'src/app/enums/theme.enum';

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 500,
  hideDelay: 200,
  touchendHideDelay: 100,
};

@Component({
  selector: 'app-main-layout',
  styleUrls: ['main-layout.component.scss'],
  templateUrl: './main-layout.component.html',
  animations: [routeAnimations],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
  ],
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  year = new Date().getFullYear();
  language: string;
  languageOptions: any[];
  onLanguageChangeSub: Subscription;
  theme: string;
  themeOptions: any[];

  isLoggedIn: boolean;

  constructor(
    public authService: AuthService,
    private userSettingsService: UserSettingsService,
    private translate: TranslateService,
    private notification: NotificationService,
    private themeService: ThemeService,
  ) {}

  ngOnInit() {
    this.language = this.userSettingsService.getUserLanguage();
    this.setLanguageOptions();
    this.setThemeOptions();

    this.onLanguageChangeSub = this.translate.onLangChange.subscribe(() => {
      this.setLanguageOptions();
      this.setThemeOptions();
    });

    this.isLoggedIn = this.authService.isLoggedIn;
    this.setThemeValue();
  }

  ngOnDestroy() {
    this.onLanguageChangeSub.unsubscribe();
  }

  async setThemeValue() {
    this.theme = ThemeEnum[await this.userSettingsService.getTheme()];
  }

  setThemeOptions() {
    this.themeOptions = this.mapOptions(getThemeOptions());
  }

  setLanguageOptions() {
    this.languageOptions = this.mapOptions(getLanguageOptions());
  }

  mapOptions(options) {
    return options.map((option) => ({
      value: option.value,
      text: this.translate.instant(option.text),
    }));
  }

  changeLanguage({ value: language }) {
    this.userSettingsService.updateUserLanguage(language);
  }

  changeTheme({ value: theme }) {
    this.userSettingsService.updateUserTheme(theme);
  }

  async signOut() {
    try {
      const confirmed = await this.notification.showConfirmationDialog({
          content: this.translate.instant('GENERAL.SIGN_OUT_PARAGRAPH', {value: name}),
          image: '/assets/images/logout_image.svg'
      });
      if (confirmed) {
          await this.authService.signOut();
      }
    } catch (_) {
        this.notification.showGenericErrorNotification();
    }
  }
}
