const langOptions = [
  {
    value: 'en',
    text: 'LANGUAGES.ENGLISH',
  },
  {
    value: 'pl',
    text: 'LANGUAGES.POLISH',
  },
];

export const getLanguageOptions = () => langOptions;

export const isSupportedLanguage = (lang) => !!langOptions.find((option) => option.value === lang);
