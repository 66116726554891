import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CreateComponent } from './create/create.component';
import { EditComponent } from './edit/edit.component';
import { InboxComponent } from './inbox/inbox.component';
import { OutboxComponent } from './outbox/outbox.component';
import { StatsComponent } from './stats/stats.component';
import { MainLayoutComponent } from '../layout/main-layout/main-layout.component';

const routes: Routes = [{
  path: '',
  component: MainLayoutComponent,
  children: [
    {
      path: '',
      redirectTo: 'inbox',
      pathMatch: 'full',
    },
    {
      path: 'create',
      component: CreateComponent,
    },
    {
      path: 'edit',
      component: EditComponent,
    },
    {
      path: 'inbox',
      component: InboxComponent,
    },
    {
      path: 'outbox',
      component: OutboxComponent,
    },
    {
      path: 'stats',
      component: StatsComponent,
    },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FeedbackRoutingModule { }
