import firebase from 'firebase/app';

const hoursInADay = 24;
const minutesInAnHour = 60;
const secondsInAMinute = 60;
const milliSecondsInASecond = 1000;
const daysMap = new Map([
  ['Mon', 1],
  ['Tue', 2],
  ['Wed', 3],
  ['Thu', 4],
  ['Fri', 5],
  ['Sat', 6],
  ['Sun', 7],
]);

const padNum = (num, size = 2) => {
  num = num.toString();
  while (num.length < size) {
    num = '0' + num;
  }
  return num;
};

const timeString = (time: number) => {
  let hours: string = Math.floor(time / 60).toString();
  let minutes: string = (time % 60).toString();

  if (hours.length === 1) {
    hours = '0' + hours;
  }
  if (minutes.length === 1) {
    minutes = '0' + minutes;
  }

  return hours + ':' + minutes;
};

export const generateTimeValues = (startTime: string, endTime: string) => {
  const start: number[] = startTime.split(':').map(Number);
  const end: number[] = endTime.split(':').map(Number);
  const startMinutes = start[0] * 60 + start[1];
  const endMinutes = end[0] * 60 + end[1];
  const result: string[] = [];

  for (let time = startMinutes; time <= endMinutes; time += 30) {
    result.push(timeString(time));
  }
  return result;
};

export const getTimeValue = (timestamp: firebase.firestore.Timestamp) => {
  const t = timestamp ? timestamp.toDate() : firebase.firestore.Timestamp.now().toDate();
  return `${padNum(t.getHours())}:${padNum(t.getMinutes())}`;
};

export const hourToTime = (hour: string) => {
  const hourMinute = hour.split(':').map(Number);
  const date = new Date();

  date.setHours(hourMinute[0]);
  date.setMinutes(hourMinute[1]);

  return firebase.firestore.Timestamp.fromDate(date);
};

export const getWeekdays = () => [
    { text: 'DAYS.MON', value: 'Mon' },
    { text: 'DAYS.TUE', value: 'Tue' },
    { text: 'DAYS.WED', value: 'Wed' },
    { text: 'DAYS.THU', value: 'Thu' },
    { text: 'DAYS.FRI', value: 'Fri' },
    { text: 'DAYS.SAT', value: 'Sat' },
    { text: 'DAYS.SUN', value: 'Sun' },
  ];

export const getNextDate = (day: string, hour: string) => {
  const nextDate = new Date();
  const nextDay = getWeekdayNumber(day);
  const daysToAdd = (nextDay + 7 - nextDate.getDay()) % 7;
  nextDate.setDate(nextDate.getDate() + daysToAdd);
  const hourMinute = hour.split(':').map(Number);
  nextDate.setHours(hourMinute[0]);
  nextDate.setMinutes(hourMinute[1]);
  nextDate.setSeconds(0);

  const now = new Date();
  if (nextDate.getTime() < now.getTime()) {
    nextDate.setDate(nextDate.getDate() + 7);
  }

  return nextDate;
};

const getWeekdayNumber = (day: string) => daysMap.get(day);

export const getSecondsToDate = (timeDifference: number) => Math.floor(
    (timeDifference / milliSecondsInASecond) % secondsInAMinute).toString(10);

export const getMinutesToDate = (timeDifference: number) => Math.floor(
    (timeDifference / (milliSecondsInASecond * minutesInAnHour)) % secondsInAMinute).toString(10);

export const getHoursToDate = (timeDifference: number) => Math.floor(
    (timeDifference / (milliSecondsInASecond * minutesInAnHour * secondsInAMinute)) % hoursInADay
  ).toString(10);

export const getDaysToDate = (timeDifference: number) => Math.floor(
    timeDifference / (milliSecondsInASecond * minutesInAnHour * secondsInAMinute * hoursInADay)
  ).toString(10);

export const convertTimestampToDateString = (timestamp: Date) => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day}`;
};
