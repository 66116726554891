import { animate, query, style, transition, trigger, stagger, sequence } from '@angular/animations';
export const ROUTE_ANIMATIONS_ELEMENTS = 'route-animations-elements';

export const routeAnimations = trigger('routeAnimations', [
  transition('* <=> *', [
    query(':enter > *', style({ opacity: 0, position: 'fixed' }), {
      optional: true,
    }),
    query(':enter .' + ROUTE_ANIMATIONS_ELEMENTS, style({ opacity: 0 }), {
      optional: true,
    }),
    sequence([
      query(
        ':leave > *',
        [
          style({ transform: 'translateY(0%)', opacity: 1 }),
          animate('0.2s ease-in-out', style({ transform: 'translateY(-3%)', opacity: 0 })),
          style({ position: 'fixed' }),
        ],
        { optional: true }
      ),
      query(
        ':enter > *',
        [
          style({
            transform: 'translateY(-3%)',
            opacity: 0,
            position: 'static',
          }),
          animate('0.4s ease-in-out', style({ transform: 'translateY(0%)', opacity: 1 })),
        ],
        { optional: true }
      ),
    ]),
    query(
      ':enter .' + ROUTE_ANIMATIONS_ELEMENTS,
      stagger(75, [
        style({ transform: 'translateY(10%)', opacity: 0 }),
        animate('0.5s ease-in-out', style({ transform: 'translateY(0%)', opacity: 1 })),
      ]),
      { optional: true }
    ),
  ]),
]);

export const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(
      ':enter',
      [
        style({
          transform: 'translateY(-5%) translateX(-3%)',
          opacity: 0,
          position: 'static',
        }),
        stagger('30ms', animate('0.4s ease-in-out', style({ transform: 'translateY(0%)', opacity: 1 }))),
      ],
      { optional: true }
    ),
    query(
      ':leave',
      [
        style({ transform: 'scale(1)', opacity: 1, height: '*' }),
        animate(
          '1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({
            opacity: 0,
            height: '0px',
            transform: 'scaleX(0.5)',
            margin: '0px',
          })
        ),
      ],
      { optional: true }
    ),
  ]),
]);

export const enterLeaveAnimation = trigger('enterLeaveAnimation', [
  transition(':enter', [
    style({
      transform: 'translateY(-10%) translateX(-3%)',
      opacity: 0,
      position: 'static',
    }),
    animate('0.5s ease-in-out', style({ transform: 'translateY(0%)', opacity: 1 })),
  ]),
  transition(':leave', [
    style({ transform: 'translateY(0%)', opacity: 1 }),
    animate('0.2s ease-in-out', style({ transform: 'translateY(-3%)', opacity: 0 })),
    style({ position: 'fixed' }),
  ]),
]);
