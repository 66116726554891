import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SecureInnerPagesGuard implements CanActivate {
  constructor(public authService: AuthService, public router: Router) {}

  canActivate() {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['feedback']);
    }
    return true;
  }
}
