import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

import { ThemeEnum } from '../enums/theme.enum';
import { AuthService } from '../services/auth.service';
import { isSupportedLanguage } from '../utils/language';
import { ThemeService } from './theme.service';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  public userSettings$: Observable<any> = new ReplaySubject();
  userSettingsDocumentRef: AngularFirestoreDocument<any>;

  constructor(
    private db: AngularFirestore,
    private authService: AuthService,
    private translate: TranslateService,
    private themeService: ThemeService,
  ) {
    if (authService.isLoggedIn === true) {
      this.userSettingsDocumentRef = this.db
        .collection('users-settings')
        .doc(this.authService.getCurrentUserUID());
      this.userSettings$ = this.userSettingsDocumentRef.snapshotChanges();
    }
  }

  updateUserSettings(value) {
    return this.userSettingsDocumentRef.update(value);
  }

  updateUserLanguage(language) {
    this.updateTranslationInApp(language);
    return this.userSettingsDocumentRef.update({ lang: language });
  }

  updateUserTheme(newTheme: string) {
    this.themeService.enableTheme(ThemeEnum[newTheme]);
    localStorage.setItem('theme', newTheme);
    return this.userSettingsDocumentRef.update({ theme: newTheme });
  }

  updateTranslationInApp(language) {
    this.translate.use(language);
    localStorage.setItem('language', language);
  }

  getUserLanguage() {
    return this.translate.currentLang;
  }

  async setDefaultSettings() {
    this.setDefaultThemeSettings();
    this.setDefaultLanguageSettings();
  }

  async getTheme(): Promise<ThemeEnum> {
    const localStorageTheme = this.getThemeFromLocalStorage();
    if (localStorageTheme) {
      return localStorageTheme;
    }

    const firebaseTheme = await this.getThemeFromFirebase();
    if (firebaseTheme) {
      return firebaseTheme;
    }

    return ThemeEnum.light;
  }

  private getThemeFromLocalStorage(): ThemeEnum {
    const browserSavedTheme: string = localStorage.getItem('theme');
    if (browserSavedTheme) {
      const returnCandidate = ThemeEnum[browserSavedTheme as keyof typeof ThemeEnum];
      if (!returnCandidate) {
        localStorage.removeItem('theme');
        return null;
      } else {
        return returnCandidate;
      }
    }
  }

  private async getThemeFromFirebase(): Promise<ThemeEnum> {
    if (this.authService.isLoggedIn) {
      const settings = await this.userSettingsDocumentRef.get().toPromise();
      let firebaseSavedTheme;
      try {
        firebaseSavedTheme = settings.data().theme;
      } catch (_) {
        firebaseSavedTheme = false;
      }
      if (firebaseSavedTheme) {
        const returnCandidate = ThemeEnum[firebaseSavedTheme as keyof typeof ThemeEnum];
        if (returnCandidate) {
          localStorage.setItem('theme', firebaseSavedTheme);
          return ThemeEnum[firebaseSavedTheme as keyof typeof ThemeEnum];
        }
      }
    }
    return null;
  }

  private async setDefaultThemeSettings() {
    const theme = await this.getTheme();
    this.themeService.enableTheme(theme);
  }

  private async setDefaultLanguageSettings() {
    const lang = await this.getLanguage();
    this.updateTranslationInApp(lang);
    this.translate.use(lang);
  }

  private async getLanguage() {
    const browserSavedLanguage = localStorage.getItem('language');
    if (isSupportedLanguage(browserSavedLanguage)) {
      return browserSavedLanguage;
    }

    if (this.authService.isLoggedIn === true) {
      const settings = await this.userSettingsDocumentRef.get().toPromise();
      const firebaseSavedLanguage = settings.data().lang;
      if (isSupportedLanguage(firebaseSavedLanguage)) {
        return firebaseSavedLanguage;
      }
    }

    const browserLanguage = this.translate.getBrowserLang();
    if (isSupportedLanguage(browserLanguage)) {
      return browserLanguage;
    }

    return this.translate.getDefaultLang();
  }
}
