import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

import { ConfirmationDialogComponent } from '../shared/notifications/confirmation-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private errorConfig = {
    duration: 5000,
    verticalPosition: 'top',
    horizontalPosition: 'right',
    panelClass: ['error-snackbar'],
  } as any;

  private successConfig = {
    duration: 3000,
    verticalPosition: 'top',
    horizontalPosition: 'right',
    panelClass: ['success-snackbar'],
  } as any;

  private infoConfig = {
    duration: 3000,
    verticalPosition: 'top',
    horizontalPosition: 'right',
    panelClass: ['info-snackbar'],
  } as any;

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar, private translate: TranslateService) {}

  setConfirmationDialogData(headerTranslateKey: string, contentTranslateKey: string, contentValue: string) {
    const data = {
      header: this.translate.instant(headerTranslateKey),
      content: this.translate.instant(contentTranslateKey, { value: contentValue }),
    };
    return data;
  }

  async showConfirmationDialog(data) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data, panelClass: 'confirmation-dialog' });
    return dialogRef.afterClosed().toPromise();
  }

  showAuthErrorNotification(error: firebase.default.auth.AuthError) {
    if (error.code === 'auth/invalid-email') {
      this.showCustomErrorNotification(this.translate.instant('PAGES.AUTH.NOTIFICATION_INVALID_MAIL'));
    } else if (error.code === 'auth/user-not-found') {
      this.showCustomErrorNotification(this.translate.instant('PAGES.AUTH.NOTIFICATION_USER_NOT_FOUND'));
    } else if (error.code === 'auth/wrong-password') {
      this.showCustomErrorNotification(this.translate.instant('PAGES.AUTH.NOTIFICATION_WRONG_PASSWORD'));
    } else if (error.code === 'auth/user-disabled') {
      this.showCustomErrorNotification(this.translate.instant('PAGES.AUTH.NOTIFICATION_USER_DISABLED'));
    } else {
      this.showGenericErrorNotification();
    }
  }

  showCustomErrorNotification(message: string) {
    this.snackBar.open(message, ':(', this.errorConfig);
  }

  showGenericErrorNotification() {
    const action = this.translate.instant('NOTIFICATIONS.SAD');
    this.snackBar.open(this.translate.instant('NOTIFICATIONS.GENERAL'), action, this.errorConfig);
  }

  showSuccessNotification(message: string) {
    const action = this.translate.instant('NOTIFICATIONS.THANKS');
    this.snackBar.open(message, action, this.successConfig);
  }

  showInfoNotification(message: string) {
    const action = this.translate.instant('NOTIFICATIONS.THANKS');
    this.snackBar.open(message, action, this.infoConfig);
  }
}
