import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import firebase from 'firebase/app';
import 'firebase/auth';

import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: any;
  isAdmin: boolean;
  signInProvider: string;

  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    private translate: TranslateService,
    private notificationSvc: NotificationService,
  ) {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        this.storeUserData(user);
        this.checkIfAdmin();
      } else {
        this.removeUserData();
        if ( this.router.url.includes('feedback') ) {
          this.router.navigate(['/auth/sign-in']);
        }
      }
    });
  }

  get isLoggedIn(): boolean {
    const user = this.getCurrentUserData();
    return user != null;
  }

  async signIn(email: string, password: string) {
    try {
      await this.afAuth.signInWithEmailAndPassword(email, password);
      const user = await this.afAuth.currentUser;

      if (user.emailVerified) {
        this.storeUserData(user);
        this.router.navigate(['feedback']);
      } else {
        this.router.navigate(['auth/verify-email']);
      }
    } catch (error) {
      this.notificationSvc.showAuthErrorNotification(error);
    }
  }

  async signUp(email: string, password: string) {
    try {
      await this.afAuth.createUserWithEmailAndPassword(email, password);
      const user = await this.afAuth.currentUser;
      await user.sendEmailVerification();
    } catch (error) {
      this.notificationSvc.showCustomErrorNotification(error.message);
    }
  }

  async sendVerificationEmail() {
    const user = await this.afAuth.currentUser;
    await user.sendEmailVerification();
  }

  async forgotPassword(passwordResetEmail: string): Promise<boolean> {
    try {
      await this.afAuth.sendPasswordResetEmail(passwordResetEmail);
      return true;
    } catch (error) {
      this.notificationSvc.showAuthErrorNotification(error);
      return false;
    }
  }

  googleAuth() {
    // TODO use AngularFireAuthModule
    return this.authLogin(new firebase.auth.GoogleAuthProvider());
  }

  async authLogin(provider: firebase.auth.AuthProvider) {
    try {
      await this.afAuth.signInWithPopup(provider);
      const user = await this.afAuth.currentUser;

      this.storeUserData(user);
      this.router.navigate(['feedback']);
    } catch (error) {
      this.notificationSvc.showCustomErrorNotification(error.message);
    }
  }

  async signOut() {
    await this.afAuth.signOut();
    this.removeUserData();
    this.router.navigate(['auth/sign-out']);
  }

  async checkIfAdmin() {
    let isHe = false;
    try {
      isHe = this.getAdminStatus();
      if (isHe == null) {
        const uid = this.getCurrentUserUID();
        const adminDoc = await this.afs.collection('admins').doc(uid).get().toPromise();
        const admin = adminDoc ? adminDoc.data() : ({} as any);

        isHe = !!admin.isAdmin;
      }
    } catch (error) {}
    this.isAdmin = isHe;
    this.storeAdminStatus(isHe);
  }

  async isSignInProviderPassword(): Promise<boolean> {
    return (await this.afAuth.currentUser).providerData[0].providerId === 'password';
  }

  async updateEmail(newEmail: string) {
    // TODO: reauthenticate https://firebase.google.com/docs/auth/web/manage-users?hl=PL#re-authenticate_a_user
    try {
      await (await this.afAuth.currentUser).updateEmail(newEmail);
    } catch (e) {
      this.notificationSvc.showGenericErrorNotification();
    } finally {
      this.notificationSvc.showSuccessNotification(this.translate.instant('NOTIFICATIONS.MAIL_CHANGED'));
    }
  }

  storeUserData(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  storeAdminStatus(isAdmin) {
    localStorage.setItem('admin', isAdmin);
  }

  removeUserData() {
    localStorage.removeItem('user');
    localStorage.removeItem('admin');
  }

  getAdminStatus() {
    return JSON.parse(localStorage.getItem('admin'));
  }

  getCurrentUserData() {
    return JSON.parse(localStorage.getItem('user'));
  }

  getCurrentUserEmail() {
    return this.getCurrentUserData().email;
  }

  getCurrentUserUID() {
    return this.getCurrentUserData().uid;
  }

  async changePassword(data) {
    // TODO use AngularFireAuthModule
    const currentUser = firebase.auth().currentUser;
    const credentials = firebase.auth.EmailAuthProvider.credential(currentUser.email, data.oldPassword);

    await currentUser.reauthenticateWithCredential(credentials);
    await currentUser.updatePassword(data.newPassword);
  }
}
