import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  isShown: BehaviorSubject<boolean> = new BehaviorSubject(false);
  spinnerName: BehaviorSubject<string> = new BehaviorSubject('');

  constructor() { }

  show(name: string) {
    this.spinnerName.next(name);
    this.isShown.next(true);
  }

  hide(name: string) {
    this.spinnerName.next(name);
    this.isShown.next(false);
  }
}
