import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { GeneralStats } from '../models/stats';
import { compareObjects } from '../utils/compareObjects';


@Injectable({
  providedIn: 'root',
})
export class StatsService {
  constructor(private db: AngularFirestore) {}

  getGeneralStats() {
    return this.db
      .collection('stats')
      .doc('general')
      .get()
      .pipe(
        map((generalStats) => generalStats.data() || {}),
        map((generalStats: any) => ({
          activeUsers: (generalStats.all || {}).registered || 0,
          anonymousFeedbackSent: (generalStats.all || {}).aSent || 0,
          feedbacksSent: (generalStats.all || {}).sent || 0,
          unsentFeedbacks: (generalStats.all || {}).toDeliver || 0,
        })),
      );
  }

  getWeeklyStats(): Promise<GeneralStats[]> {
    return this.db
      .collection('stats')
      .doc('general')
      .get()
      .pipe(
        map((queryResult) => {
          const dataObj = (queryResult.data() || {}) as any;
          return (dataObj.weekly || []).map(
            (data) =>
              ({
                allFeedback: (data.sent || 0) + (data.aSent || 0),
                anonymousFeedbackSent: data.aSent || 0,
                feedbacksSent: data.sent || 0,
                activeUsers: data.active,
                at: data.at.toDate(),
              } as GeneralStats),
          );
        }),
      )
      .toPromise();
  }

  async getLastQuarterWeeklyStats() {
    const allstats = await this.getWeeklyStats();
    allstats.sort((stat1, stat2) => compareObjects(stat1, stat2, 'at'));
    const lastQuarterStats = allstats.slice(Math.max(allstats.length - 15, 0));

    return lastQuarterStats;
  }

  getUserStats(uid: string) {
    return this.db
      .collection('users-stats')
      .doc(uid)
      .snapshotChanges()
      .pipe(
        map((userStats) => userStats.payload.data() || {}),
        map((userStats: any) => ({
          feedbacksReceived: (userStats.all || {}).recv || 0,
          feedbacksSent: (userStats.all || {}).sent || 0,
        })),
      );
  }

  getTopUserStats() {
    return this.db
      .collection('users-stats', (queryFn) => queryFn.orderBy('all.sent', 'desc').limit(10))
      .get()
      .pipe(
        map((userStats) =>
          userStats.docs.map((doc) => ({
            ...(doc.data() || ({} as any)),
            uid: doc.id,
          })),
        ),
        mergeMap((items) => of(...items)),
      );
  }
}
