import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { TranslateModule } from '@ngx-translate/core';
import { TagInputModule } from 'ngx-chips';

import { FeedbackRoutingModule } from './feedback-routing.module';
import { CommonComponentsModule } from '../modules/common-components.module';
import { OutboxComponent } from './outbox/outbox.component';
import { InboxComponent } from './inbox/inbox.component';
import { MaterialModule } from '../modules/material.module';
import { StatsComponent } from './stats/stats.component';
import { CountdownComponent } from '../shared/countdown/countdown.component';
import { CreateComponent } from './create/create.component';
import { CreateSuccessDialogComponent } from './create-success-dialog/create-success-dialog.component';
import { LayoutModule } from '../layout/layout.module';
import { EditComponent } from './edit/edit.component';
import { StatCardComponent } from './stats/stat-card/stat-card.component';

@NgModule({
  imports: [
    ChartsModule,
    CommonModule,
    CommonComponentsModule,
    FeedbackRoutingModule,
    FlexLayoutModule,
    FormsModule,
    LayoutModule,
    MaterialModule,
    ReactiveFormsModule,
    TagInputModule,
    TranslateModule,
  ],
  declarations: [
    CountdownComponent,
    CreateComponent,
    CreateSuccessDialogComponent,
    EditComponent,
    InboxComponent,
    OutboxComponent,
    StatCardComponent,
    StatsComponent,
  ],
  entryComponents: [CreateSuccessDialogComponent],
})
export class FeedbackModule {}
