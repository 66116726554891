const themeOptions = [
    {
        value: 'light',
        text: 'THEMES.LIGHT',
    },
    {
        value: 'dark',
        text: 'THEMES.DARK',
    },
];

export const getThemeOptions = () => themeOptions;
