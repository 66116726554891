import { Component, OnInit } from '@angular/core';

import { enterLeaveAnimation } from '../../animations/animations';


@Component({
  selector: 'app-cookie-widget',
  templateUrl: './cookie-widget.component.html',
  styleUrls: ['./cookie-widget.component.scss'],
  animations: [enterLeaveAnimation],
})
export class CookieWidgetComponent implements OnInit {

  cookiesAccepted: boolean;
  localStorageKey = 'cks';

  constructor() { }

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem(this.localStorageKey))) {
      this.cookiesAccepted = true;
    };
  }

  acceptCookies(): void {
    this.cookiesAccepted = true;
    localStorage.setItem(this.localStorageKey, 'true');
  }

}
