import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/firestore';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MarkdownModule } from 'ngx-markdown';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app-root/app-root.component';
import { environment } from '../environments/environment';
import { FeedbackModule } from './feedback/feedback.module';
import { LayoutModule } from './layout/layout.module';
import { MaterialModule } from './modules/material.module';
import { AppRoutingModule } from './app-routing.module';
import { AuthService } from './services/auth.service';
import { ConfirmationDialogComponent } from './shared/notifications/confirmation-dialog.component';
import { NotFoundComponent } from './static/not-found/not-found.component';
import { CookieWidgetComponent } from './shared/cookie-widget/cookie-widget.component';

const httpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({
  declarations: [
    AppComponent,
    ConfirmationDialogComponent,
    NotFoundComponent,
    CookieWidgetComponent,
  ],
  entryComponents: [
    ConfirmationDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    AngularFireAuthModule,
    AngularFirestoreModule,
    MarkdownModule.forRoot({ loader: HttpClient }),
    MaterialModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    FeedbackModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      }
    }),
  ],

  providers: [
    AuthService,
    {
      provide: SETTINGS,
      useValue: environment.local ? {
        host: 'localhost:8080',
        ssl: false
      } : undefined
    },
    {provide: Window, useValue: window},
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
