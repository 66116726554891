import { Component, OnDestroy, OnInit, AfterViewInit, ViewEncapsulation, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { AuthService } from '../../services/auth.service';
import { UsersService } from '../../services/users.service';
import { FeedbackService } from '../../services/feedback.service';
import { NotificationService } from '../../services/notification.service';
import { CreateSuccessDialogComponent } from '../create-success-dialog/create-success-dialog.component';

interface AutoCompleteModel {
  value: any;
  display: string;
}

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateComponent implements OnInit, OnDestroy, AfterViewInit {
  users: AutoCompleteModel[] = [];
  feedbackForm: FormGroup;
  startTime: Date;
  allUsersSub: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private usersService: UsersService,
    private feedbackService: FeedbackService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private notificationSvc: NotificationService,
    private authService: AuthService,
    private translate: TranslateService,
    private elementRef: ElementRef,
  ) {}

  ngOnInit() {
    const currentUserUid = this.authService.getCurrentUserUID();
    const preselectedIds = this.activatedRoute.snapshot.queryParams.to || [];

    this.feedbackForm = this.formBuilder.group({
      to: [[], Validators.required],
      content: ['', [Validators.required, Validators.maxLength(1000)]],
      anonymous: [false],
    });

    this.allUsersSub = this.usersService.getAllUsers().subscribe((users) => {
      users.forEach((user) => {
        const selectableUser = {
          display: user.displayName || user.email,
          photoURL: user.photoURL,
          value: user.uid,
        };

        if (selectableUser.value !== currentUserUid) {
          this.users.push(selectableUser);
        }
        if (preselectedIds.includes(user.uid)) {
          this.feedbackForm.patchValue({
            to: [...this.feedbackForm.value.to, selectableUser],
          });
        }
      });
      this.feedbackForm.valueChanges.subscribe(() => this.startTimer());
    });
  }

  ngAfterViewInit() {
    // setTimeout is used to prevent ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      if (this.activatedRoute.snapshot.queryParams.to) {
        this.elementRef.nativeElement.querySelectorAll('#feedbackContent')[0].focus();
      } else {
        // QuerySelector is used because element cannot be accessed with @ViewChild
        this.elementRef.nativeElement.querySelectorAll('.feedback-to-tag input')[0].focus();
      }
    });
  }

  ngOnDestroy() {
    this.allUsersSub.unsubscribe();
  }

  hasError(controlName: string, errorName: string) {
    return this.feedbackForm.controls[controlName].hasError(errorName);
  }

  async onSubmit(value) {
    if (this.feedbackForm.valid) {
      const time = this.stopTimer();
      const payload = {
        ...value,
        to: value.to.map((u) => u.value),
        time,
      };

      try {
        await this.feedbackService.createFeedback(payload);
        this.dialog
          .open(CreateSuccessDialogComponent, {
            data: { time },
          })
          .afterClosed()
          .subscribe(() => {
            this.feedbackForm.reset();
            this.router.navigate(['/feedback']);
          });
      } catch (error) {
        this.notificationSvc.showCustomErrorNotification(this.translate.instant('NOTIFICATIONS.ERROR_FEEDBACK'));
      }
    }
  }

  startTimer() {
    if (!this.startTime) {
      this.startTime = new Date();
    }
  }

  stopTimer() {
    const startTime = this.startTime ? this.startTime.getTime() : new Date().getTime();
    const timeToCreate = new Date().getTime() - startTime;
    delete this.startTime;
    return Math.round(timeToCreate / 1000);
  }
}
