import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs/internal/Subscription';

import { AuthService } from '../../services/auth.service';
import { UsersService } from '../../services/users.service';
import { FeedbackService } from '../../services/feedback.service';
import { CreateSuccessDialogComponent } from '../create-success-dialog/create-success-dialog.component';
import { NotificationService } from '../../services/notification.service';
import { enterLeaveAnimation } from '../../animations/animations';

interface AutoCompleteModel {
  value: any;
  display: string;
}

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [enterLeaveAnimation],
})
export class EditComponent implements OnInit, OnDestroy {
  users: AutoCompleteModel[] = [];
  feedbackForm: FormGroup;
  startTime: Date;
  feedbackId: string;
  feedback;
  isFormGenerated = false;
  isFeedbackEditable;
  subscriptions: Subscription[] = [];
  singleFeedbackSub: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private usersService: UsersService,
    private feedbackService: FeedbackService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private notificationSvc: NotificationService,
    private authService: AuthService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.feedbackId = this.activatedRoute.snapshot.queryParams.id;
    if (this.feedbackId) {
      this.singleFeedbackSub = this.feedbackService.getSingleSentFeedback(this.feedbackId).subscribe(
        (feedback) => {
          this.feedback = feedback.payload.data();
          this.validateFeedback();
        },
        () => {
          this.doNotAllowEdit();
        },
      );
    } else {
      this.doNotAllowEdit();
    }
  }

  ngOnDestroy() {
    if (this.singleFeedbackSub) {
      this.singleFeedbackSub.unsubscribe();
    }
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  validateFeedback() {
    if (this.feedback.delivered === false && this.feedback.from === this.authService.getCurrentUserUID()) {
      this.isFeedbackEditable = true;
    } else {
      this.isFeedbackEditable = false;
    }

    if (this.isFeedbackEditable) {
      this.createFeedbackForm();
    } else {
      this.doNotAllowEdit();
    }
  }

  doNotAllowEdit() {
    this.router.navigate(['error/404']);
  }

  createFeedbackForm() {
    this.feedbackForm = this.formBuilder.group({
      to: [[], Validators.required],
      content: [this.feedback.content, [Validators.required, Validators.maxLength(1000)]],
      anonymous: [this.feedback.content.anonymous],
    });

    const currentUserUid = this.authService.getCurrentUserUID();
    const usersSub = this.usersService.getAllUsers().subscribe((users) => {
      users.forEach((user) => {
        const selectableUser = {
          display: user.displayName || user.email,
          value: user.uid,
        };

        if (selectableUser.value !== currentUserUid) {
          this.users.push(selectableUser);
        }
        if (this.feedback.to.includes(user.uid)) {
          this.feedbackForm.patchValue({
            to: [...this.feedbackForm.value.to, selectableUser],
          });
        }
      });
      this.isFormGenerated = true;
      this.feedbackForm.valueChanges.subscribe(() => this.startTimer());
    });
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions.push(usersSub);
  }

  hasError(controlName: string, errorName: string) {
    return this.feedbackForm.controls[controlName].hasError(errorName);
  }

  async onSubmit(value) {
    if (this.feedbackForm.valid) {
      const time = this.stopTimer();
      const payload = {
        ...value,
        to: value.to.map((u) => u.value),
        time,
      };

      try {
        await this.feedbackService.updateFeedback(payload, this.feedbackId);
        this.dialog
          .open(CreateSuccessDialogComponent, {
            data: { time },
          })
          .afterClosed()
          .subscribe(() => {
            this.feedbackForm.reset();
            this.router.navigate(['/feedback/outbox']);
          });
      } catch (error) {
        this.notificationSvc.showCustomErrorNotification(this.translate.instant('NOTIFICATIONS.ERROR_FEEDBACK'));
        // TODO: handle stuff like: "permission-denied"
      }
    }
  }

  startTimer() {
    if (!this.startTime) {
      this.startTime = new Date();
    }
  }

  stopTimer() {
    const startTime = this.startTime ? this.startTime.getTime() : new Date().getTime();
    const timeToCreate = new Date().getTime() - startTime;
    delete this.startTime;
    return Math.round(timeToCreate / 1000);
  }
}
