import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Subscription, interval } from 'rxjs';

import { listAnimation, enterLeaveAnimation } from '../../animations/animations';
import { getSecondsToDate, getMinutesToDate, getHoursToDate, getDaysToDate } from '../../utils/date';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
  animations: [listAnimation, enterLeaveAnimation],
})
export class CountdownComponent implements OnChanges, OnDestroy {
  @Input() date: Date;

  secondsToDate: string;
  minutesToDate: string;
  hoursToDate: string;
  daysToDate: string;
  private subscription: Subscription;

  constructor() {}

  ngOnChanges() {
    if (this.date) {
      this.subscription = interval(1000).subscribe(() => {
        this.getTimeDifference();
      });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private getTimeDifference() {
    const timeDifference = this.date.getTime() - new Date().getTime();
    this.secondsToDate = getSecondsToDate(timeDifference);
    this.minutesToDate = getMinutesToDate(timeDifference);
    this.hoursToDate = getHoursToDate(timeDifference);
    this.daysToDate = getDaysToDate(timeDifference);
    if (parseInt(this.daysToDate, 10) < 0 ) {
      this.date.setDate(this.date.getDate() + 7);
      this.getTimeDifference();
    }
  }

}
