import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { SpinnerService } from './../../services/spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  @Input() loadingText: string;
  @Input() name: string;
  showSpinner = false;
  loadingStartTime: number;
  minLoadingTime = 700;

  constructor(private spinner: NgxSpinnerService, private spinnerService: SpinnerService) {}

  show() {
    this.spinner.show(this.name, {
      type: 'ball-scale-pulse',
      fullScreen: false,
      color: '#3f51b5',
      bdColor: 'rgba(51,51,51,0.0)',
      size: 'medium',
    });
    this.loadingStartTime = Date.now();
  }

  hide() {
    const loadingTime = Date.now() - this.loadingStartTime;
    if (loadingTime >= this.minLoadingTime) {
      this.spinner.hide(this.name);
    } else {
      setTimeout(() => {
        this.spinner.hide(this.name);
      }, this.minLoadingTime - loadingTime);
    }
  }

  ngOnInit() {
    this.spinnerService.isShown.subscribe((isShown: boolean) => {
      if (this.spinnerService.spinnerName.getValue() === this.name) {
        if (isShown === true) {
          this.show();
        } else {
          this.hide();
        }
      }
    });
  }
}
