import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { OverlayContainer } from '@angular/cdk/overlay';

import { UserSettingsService } from '../services/user-settings.service';
import { ThemeEnum } from './../enums/theme.enum';
import { ThemeService } from '../services/theme.service';


@Component({
  selector: 'app-root',
  templateUrl: './app-root.component.html',
  styleUrls: ['./app-root.component.scss']
})
export class AppComponent implements OnInit{
  title = 'Feedbucket';
  theme = ThemeEnum.dark;
  enabledTheme = 'light-theme';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private userSettingsService: UserSettingsService,
    private overlayContainer: OverlayContainer,
    private themeService: ThemeService,
    ) {
    this.matIconRegistry.addSvgIcon(
      `fb_logo`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/fb_logo.svg`)
    );
    this.userSettingsService.setDefaultSettings();

  }

  ngOnInit(): void {
    this.themeService.enabledTheme.subscribe(theme => {
      this.enabledTheme = theme;
      const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
      const themeClassesToRemove = Array.from(overlayContainerClasses).filter((item: string) => item.includes('-theme'));
      if (themeClassesToRemove.length) {
         overlayContainerClasses.remove(...themeClassesToRemove);
      }
      overlayContainerClasses.add(theme);
    });
  }

}
