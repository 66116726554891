import { Component, Input, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { enterLeaveAnimation } from '../../../animations/animations';

@Component({
  selector: 'app-stat-card',
  templateUrl: './stat-card.component.html',
  styleUrls: ['./stat-card.component.scss'],
  animations: [enterLeaveAnimation],
})
export class StatCardComponent implements OnChanges {
  @Input() metric: string;
  @Input() text: string;
  @Input() icon: string;
  @Input() color: string;

  translatedText: string;

  isReady = false;

  constructor(private translate: TranslateService) {}

  ngOnChanges(): void {
    this.translatedText = this.translate.instant(this.text);
    this.isReady = this.metric !== undefined;

  }
}
