import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs/internal/Observable';

import { Settings } from '../models/settings.model';

@Injectable({
    providedIn: 'root'
})
export class AdminService {
    adminsCollectionRef: AngularFirestoreCollection<any>;
    deactivatedCollectionRef: AngularFirestoreCollection<any>;
    settings$: Observable<any>;
    settingsDocumentRef: AngularFirestoreDocument<any>;

    constructor(public db: AngularFirestore) {
        this.adminsCollectionRef = this.db.collection('admins');
        this.deactivatedCollectionRef = this.db.collection('deactivated');
        this.settingsDocumentRef = this.db.collection<Settings>('settings').doc('general');
        this.settings$ = this.settingsDocumentRef.snapshotChanges();
    }

    getFeedbucketSettings() {
        return this.settings$;
    }

    updateSettings(value) {
        return this.settingsDocumentRef.set(value);
    }

    makeAdmin(uid: string) {
        return this.adminsCollectionRef.doc(uid).set({
            uid,
            isAdmin: 'true'
        });
    }

    unMakeAdmin(uid: string) {
        return this.adminsCollectionRef.doc(uid).delete();
    }

    deactivateUser(uid: string) {
        return this.deactivatedCollectionRef.doc(uid).set({
            uid,
            isActive: false
        });
    }

    activateUser(uid: string) {
        return this.deactivatedCollectionRef.doc(uid).delete();
    }
}
